<template>
  <div>
    <vs-popup class="sm:popup-w-60" title="Filter" :active="isActive" v-on:update:active="emitModalIsActive">

      <div>
        <div class="vx-row">
          <div class="vx-col w-full">
            <ValidationErrors :errors="errors"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/2 w-full">
            <label class="ml-1 text-xs">Periode Mulai</label>
            <vx-input-group>
              <flat-pickr class="w-full" v-model="data.tgl_mulai"></flat-pickr>
              <template slot="append">
                <div class="append-text btn-addon">
                  <vs-button type="filled" icon-pack="feather" icon="icon-calendar"/>
                </div>
              </template>
            </vx-input-group>
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <label class="ml-1 text-xs">Periode Selesai</label>
            <vx-input-group>
              <flat-pickr class="w-full"  v-model="data.tgl_selesai"></flat-pickr>
              <template slot="append">
                <div class="append-text btn-addon">
                  <vs-button type="filled" icon-pack="feather" icon="icon-calendar"/>
                </div>
              </template>
            </vx-input-group>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/3 w-full">
            <label class="ml-1 text-xs">Proyek</label>
            <v-select :options="proyeks" label="nama" :reduce="item => item.id" :clearable="false" v-model="data.id_proyek"/>
          </div>
          <div class="vx-col sm:w-1/3 w-full">
            <label class="ml-1 text-xs">Staf</label>
            <v-select :options="stafs" label="nama" :reduce="item => item.id" :clearable="false" v-model="data.id_staf"/>
          </div>
          <div class="vx-col sm:w-1/3 w-full">
            <label class="ml-1 text-xs">Sumber Info</label>
            <v-select :options="sumberInfos" label="nama" :reduce="item => item.id" :clearable="false" v-model="data.id_sumber_info"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/2 w-full">
            <label class="ml-1 text-xs">Hubungi Via *</label>
            <v-select :options="hubProspeks" :clearable="false" v-model="data.hub_prospek"/>
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <label class="ml-1 text-xs">Status</label>
            <v-select :options="statuses" :clearable="false" v-model="data.status"/>
          </div>
        </div>
        <div class="vx-row float-right mt-6">
          <div class="vx-col w-full">
            <vs-button class="mr-3" color="danger" type="border" @click="clearFilter" :disabled="isEmptyFilter">Clear Filter</vs-button>
            <vs-button class="mr-3" color="primary" @click="filter" :disabled="isEmptyFilter">Filter</vs-button>
          </div>
        </div>
      </div>

    </vs-popup>
  </div>
</template>

<script>
import ProyekRepository from '@/repositories/master/proyek-repository'
import StafRepository from '@/repositories/master/staf-repository'
import SumberInfoRepository from '@/repositories/master/sumber-info-repository'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import 'flatpickr/dist/flatpickr.css'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import _ from 'lodash'

export default {
  name: 'AktifitasMarketingFilter',
  props: ['isActive'],
  components: {
    ValidationErrors,
    flatPickr,
    'v-select': vSelect
  },
  computed: {
    isEmptyFilter () {
      return _.isEmpty(this.data)
    }
  },
  watch: {
    isActive (value) {
      if (value === true) {
        if (this.isFirstActive) {
          this.getAllProyek()
          this.getAllStaf()
          this.getAllSumberInfo()
          this.isFirstActive = false
        }
      }
    }
  },
  data () {
    return {
      isFirstActive: true,
      isLoading: false,
      errors: null,
      data: {},
      stafs: [],
      proyeks: [],
      sumberInfos: [],
      hubProspeks: ['Call', 'Meet', 'SMS', 'WA', 'Email'],
      statuses: ['FOLLOW UP/CALL 1', 'FOLLOW UP/CALL 2', 'FOLLOW UP/CALL 3', 'CLOSING DEAL', 'CANCEL']
    }
  },
  methods: {
    filter () {
      this.$emit('filter', this.data)
      this.emitModalIsActive(false)
    },

    clearFilter () {
      this.data = {}
      this.$emit('filter', {})
      this.emitModalIsActive(false)
    },

    getAllProyek () {
      const params = {
        filter: JSON.stringify({
          only_authorized_user: 1
        })
      }
      ProyekRepository.getAll(params)
        .then(response => {
          this.proyeks = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    getAllStaf () {
      StafRepository.getAll()
        .then(response => {
          this.stafs = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    getAllSumberInfo () {
      SumberInfoRepository.getAllDistinct()
        .then(response => {
          this.sumberInfos = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>
